<template>
  <div class="add-creative">
    <div class="add-creative__content" v-loading="loading">
      <div>
        <div class="add-creative__title">
          Загрузить креативы
        </div>
        <div class="add-creative__reservation">
          <div class="add-creative__sub-title">Бронь</div>
          <Selector
            ref="creativeGroup"
            class="add-creative__selector add-creative__input form-input"
            limit-options-height
            :title="'Группа креативов'"
            :default-select-item-id="form.CreativeGroupId || 'NoCreativeGroup'"
            :options="creativeGroupOptions"
            :select-fitrst-item="false"
            @handleChange="handleChangeCreativeGroup"
          />
          <div
            class="form-input"
            :class="{
              'form-input--full' : form.CreativeGroupName,
              'form-input--error' : errors.CreativeGroupName,
              'form-input--disabled': form.CreativeGroupId
            }"
          >
            <input 
              v-model="form.CreativeGroupName" 
              type="text" 
              :disabled="form.CreativeGroupId"
            >
            <span class="form-input__label">Наименование группы*</span>
            <template v-if="errors.CreativeGroupName && errors.CreativeGroupName.length">
              <div
                v-for="error in errors.CreativeGroupName"
                :key="error"
                class="form-input__error"
              >{{ error }}</div>
            </template>
          </div>
          <Selector
            ref="typeContract"
            class="add-creative__selector add-creative__input form-input"
            required
            :title="'Тип рекламной компании*'"
            :options="Object.keys(AdType).map((key) => ({ id: key, name: AdType[key] }))"
            :select-fitrst-item="false"
            :disabled="!!form.CreativeGroupId"
            :default-select-item-id="form.Type"
            @handleChange="(val) => form.Type = val"
          />
          <div class="add-creative__date-picker-group form-input">
            <div class="add-creative__date-picker-wrapper">
              <SingleDatePicker
                class="add-creative__date-picker"
                title="Дата начала"
                :class="{'form-input--disabled': form.CreativeGroupId}"
                :disabled="!!form.CreativeGroupId"
                :value="form.CreativeGroupStartDate"
                @changeDates="changeCreativeGroupStartDate"
              />
              <template v-if="errors.CreativeGroupStartDate && errors.CreativeGroupStartDate.length">
                <div
                  v-for="error in errors.CreativeGroupStartDate"
                  :key="error"
                  class="form-input__error"
                >{{ error }}</div>
              </template>
            </div>    
            <div class="add-creative__date-picker-wrapper">
              <SingleDatePicker
                class="add-creative__date-picker"
                title="Дата окончания"
                :class="{'form-input--disabled': form.CreativeGroupId}"
                :disabled="!!form.CreativeGroupId"
                :value="form.CreativeGroupEndDate"
                @changeDates="changeCreativeGroupEndDate"
              />
              <template v-if="errors.CreativeGroupEndDate && errors.CreativeGroupEndDate.length">
                <div
                  v-for="error in errors.CreativeGroupEndDate"
                  :key="error"
                  class="form-input__error"
                >{{ error }}</div>
              </template>
            </div>
          </div>
          <Selector
            ref="adForm"
            class="add-creative__selector add-creative__input form-input"
            required
            :disabled="!!form.CreativeGroupId"
            :title="'Форма распространения*'"
            :default-select-item-id="form.Form"
            :options="Object.keys(AdForm).map((key) => ({ id: key, name: AdForm[key] }))"
            :select-fitrst-item="false"
            @handleChange="(val) => changeAdForm(val)"
          />
          <div
            class="form-input"
            :class="{
              'form-input--full': form.Description, 
              'form-input--error': errors.Description,
              'form-input--disabled': form.CreativeGroupId,
            }"
          >
            <input 
              v-model="form.Description" 
              type="text"
              :disabled="form.CreativeGroupId"
            >
            <span class="form-input__label">Общее описание объекта рекламирования*</span>
            <Tooltip class="add-creative__tooltip">
              <div style="padding: 0 1rem">
                <b>Укажите:</b>
                <ul>
                  <li>Бренд (или несколько брендов) рекламируемых товаров или услуг;</li>
                  <li>Вид товара / услуги;</li>
                  <li>Дополнительную информацию.</li>
                </ul>
                <b style="display: block; margin-top: .5rem;">Примеры:</b>
                <ul>
                  <li>“Tide.Стиральный порошок.Годовой запас в подарок.”</li>
                  <li>“Отель «Ромашка». Бронирование номеров.Гостиница, отель в Подмосковье на выходные.”.</li>
                </ul>
              </div>
            </Tooltip>
          </div>
          <template v-if="errors.Description && errors.Description.length">
            <div
              v-for="error in errors.Description"
              :key="error"
              class="form-input__error"
            >{{ error }}</div>
          </template>
        </div>
        <div 
          v-if="form.Form" 
          class="add-creative__creative"
        >
          <div class="add-creative__sub-title">Креатив</div>
          <div
            v-for="(AdvertiserUrl, index) in form.AdvertiserUrls"
            :key="index"
            class="form-input"
            :class="{
              'form-input--full' : form.AdvertiserUrls[index].length,
              'form-input--error' : errors.AdvertiserUrls,
              'form-input--disabled' : form.NoTargetLinks
            }"
          >
            <input 
              :disabled="form.NoTargetLinks"
              :value="AdvertiserUrl"
              @input="form.AdvertiserUrls.splice(index, 1, $event.target.value)" 
              type="text" 
            >
            <span class="form-input__label">Целевая ссылка</span>
            <div class="form-input__icons-group">
              <img
                v-if="index === 0"
                src="@/assets/icons/icon-add-gray.svg"
                class="form-input__icon-item"
                @click="form.AdvertiserUrls.push('')"
              >
              <img
                v-if="index !== 0"
                src="@/assets/icons/icon-close.svg"
                class="form-input__icon-item"
                @click="form.AdvertiserUrls.splice(index, 1)"
              >
            </div>
            <Tooltip class="add-creative__tooltip">
              <div style="padding: 0 1rem">
                <b>Целевая ссылка</b> – посадочная страница, которая ведет пользователя с креатива на ресурс Рекламодателя на страницу рекламируемого товара. Достаточно указать url конкретной страницы, но если utm метка влияет на вид страницы, то ссылку указываем с utm или добавляем несколько ссылок.
              </div>
            </Tooltip>
          </div>
          <template v-if="errors.AdvertiserUrls && errors.AdvertiserUrls.length">
            <div
              v-for="error in errors.AdvertiserUrls"
              :key="error"
              class="form-input__error"
            >{{ error }}</div>
          </template>
          <template v-if="form.Form === AdFormKeys.TEXT">
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="form.NoTargetLinks"
                  @change="changeNoTargetLinks"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Нет целевых ссылок
                  </span>
                </span>
              </label>
            </div>
            <div
              v-for="({ TextData }, index) in form.TextData"
              :key="formDataTextUniqKeys[index]"
              class="form-input"
              :class="[
                {'form-input--full' : TextData},
                {'form-input--error' : errors.TextData}
              ]"
            >
              <input 
                :value="TextData" 
                @input="form.TextData.splice(index, 1, { TextData: $event.target.value })" 
                type="text"
              >
              <span class="form-input__label">Текстовые данные креатива*</span>
              <div class="form-input__icons-group">
                <img
                  v-if="index === 0"
                  src="@/assets/icons/icon-add-gray.svg"
                  class="form-input__icon-item"
                  @click="form.TextData.push({ TextData: '' }); formDataTextUniqKeys.push(getRandomInteger()); "
                >
                <img
                  v-if="index !== 0"
                  src="@/assets/icons/icon-close.svg"
                  class="form-input__icon-item"
                  @click="form.TextData.splice(index, 1); formDataTextUniqKeys.splice(index, 1)"
                >
              </div>
              <template v-if="errors.TextData && errors.TextData.length">
                <div
                  v-for="error in errors.TextData"
                  :key="error"
                  class="form-input__error"
                >{{ error }}</div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="add-creative__media-data">
              <div class="form-input">
                <label class="input__item-checkbox">
                  <input
                    type="checkbox"
                    v-model="form.NoTargetLinks"
                    @change="changeNoTargetLinks"
                  >
                  <span class="input__item-checkbox__check">
                    <img src="@/assets/icons/icon-done.svg">
                  </span>
                  <span class="input__item-checkbox__info">
                    <span class="input__item-checkbox__title">
                      Нет целевых ссылок
                    </span>
                  </span>
                </label>
              </div>
              <div
                class="form-input"
                :class="{
                  'form-input--full' : form.MediaDataDescription,
                  // 'form-input--error' : errors.CreativeGroupName,
                }"
              >
                <input 
                  v-model="form.MediaDataDescription" 
                  type="text" 
                >
                <span class="form-input__label">Описание изображения группы</span>
              </div>
              <div class="form-input">
                <div v-if="form.MediaData.length" class="add-creative__upload-files">
                  <div 
                    v-for="(MediaDataItem, index) in form.MediaData" 
                    :key="MediaDataItem.FileContentBase64" 
                    class="add-creative__upload-files-item"
                  >
                    <span v-text="MediaDataItem.FileName" />
                    <button 
                      class="add-creative__upload-files-delete" 
                      @click="form.MediaData.splice(index, 1)"
                    >
                      <img src="@/assets/icons/icon-close.svg" alt="">
                    </button>
                  </div>
                </div>
                <label class="add-creative__upload">
                  <div class="button button--empty">Прикрепить файл(ы)*</div>
                  <input 
                    multiple 
                    type="file" 
                    @change="checkFile($event, form.MediaData)"
                  >
                  
                </label>
                <Tooltip class="add-creative__tooltip add-creative__tooltip-attached-to-bottom">
                  <div style="padding: 0 1rem">
                    Вам нужно загрузить один креатив, состоящий из одного файла. Загружаем один файл (не архив) - получаем один токен. Вы можете выбрать сразу несколько файлов в одной папке на Вашем компьютере удерживая shift (или ctrl). При этом на каждый файл будет назначен отдельный токен.
                  </div>
                </Tooltip>
              </div>
            </div>
          </template>
          <div 
            v-for="(AdditionalCreative, index) in form.AdditionalCreatives"
            :key="additionalCreativesKeys[index]"
            class="add-creative__additional-creative"
          >
            <div class="add-creative__delete-btn-wrapper">
              <button 
                class="add-creative__upload-files-delete" 
                @click="form.AdditionalCreatives.splice(index, 1); additionalCreativesKeys.splice(index, 1)"
              >
                <img src="@/assets/icons/icon-close.svg" alt="">
              </button>
            </div>
            <div
              v-for="(AdvertiserUrl, index) in AdditionalCreative.AdvertiserUrls"
              :key="index"
              class="form-input"
              :class="{
                'form-input--full' : AdditionalCreative.AdvertiserUrls[index].length,
                'form-input--error' : errors.AdvertiserUrls,
                'form-input--disabled' : AdditionalCreative.NoTargetLinks
              }"
            >
              <input 
                :disabled="AdditionalCreative.NoTargetLinks"
                :value="AdvertiserUrl"
                @input="AdditionalCreative.AdvertiserUrls.splice(index, 1, $event.target.value)" 
                type="text" 
              >
              <span class="form-input__label">Целевая ссылка</span>
              <div class="form-input__icons-group">
                <img
                  v-if="index === 0"
                  src="@/assets/icons/icon-add-gray.svg"
                  class="form-input__icon-item"
                  @click="AdditionalCreative.AdvertiserUrls.push('')"
                >
                <img
                  v-if="index !== 0"
                  src="@/assets/icons/icon-close.svg"
                  class="form-input__icon-item"
                  @click="AdditionalCreative.AdvertiserUrls.splice(index, 1)"
                >
              </div>
            </div>
            <div class="form-input">
              <label class="input__item-checkbox">
                <input
                  type="checkbox"
                  v-model="AdditionalCreative.NoTargetLinks"
                  @change="AdditionalCreative.AdvertiserUrls = ['']"
                >
                <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    Нет целевых ссылок
                  </span>
                </span>
              </label>
            </div>
            <div
              class="form-input"
              :class="{
                'form-input--full' : AdditionalCreative.MediaDataDescription,
              }"
            >
              <input 
                v-model="AdditionalCreative.MediaDataDescription" 
                type="text" 
              >
              <span class="form-input__label">Описание изображения группы</span>
            </div>
            <div class="form-input">
              <div v-if="AdditionalCreative.MediaData.length" class="add-creative__upload-files">
                <div 
                  v-for="(MediaDataItem, mediaDataIndex) in AdditionalCreative.MediaData" 
                  :key="MediaDataItem.FileContentBase64" 
                  class="add-creative__upload-files-item"
                >
                  <span v-text="MediaDataItem.FileName" />
                  <button 
                    class="add-creative__upload-files-delete" 
                    @click="AdditionalCreative.MediaData.splice(mediaDataIndex, 1)"
                  >
                    <img src="@/assets/icons/icon-close.svg" alt="">
                  </button>
                </div>
              </div>
              <label class="add-creative__upload">
                <div class="button button--empty">Прикрепить файл(ы)</div>
                <input 
                  multiple 
                  type="file" 
                  @change="checkFile($event, form.AdditionalCreatives[index].MediaData)"
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="add-creative__group-btns">
        <template v-if="errors.global && errors.global.length">
            <div
              v-for="error in errors.global"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
        </template>
        <div class="add-creative__btns-wrapper">
          <ButtonElement
            @click-button="createCreative"
            text="Сохранить"
            class="add-creative__save-btn"
            :disabled="isBtnDisabled"
          />
          <ButtonElement
            @click-button="closeDialog"
            class="add-creative__cancel-btn button--empty"
            :text="'Отменить'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import { ContractType, VatType, ContractVariant } from "@/utils/contracts";
import { getRandomInteger, toBase64 } from "@/utils/helpers";
import { AdType, AdForm, AdFormKeys } from "@/utils/creatives";
import SingleDatePicker from "@/components/sort/SingleDatePicker";
import fixDateFormat from '@/mixin/fixDate'
import { mapGetters, mapState, mapMutations } from "vuex"
import Tooltip from '@/components/elements/Tooltip.vue';

const form = () => ({
  CreativeGroupId: null,
  CreativeGroupName: "",
  CreativeGroupStartDate: null,
  CreativeGroupEndDate: null,
  IsSelfPromotion: false,
  FinalContractId: null,
  Type: "",
  Form: "",
  AdvertiserUrls: [""],
  Description: "",
  IsNative: false,
  IsSocial: false,
  TextData: [
    { TextData: "" }
  ],
  OkvedCodes: [],
  TargetAudienceParams: {
    Geo: ['']
  },
  MediaData: [],
  MediaDataDescription: null, /* для всех объектов MediaData => Description  */
  AdditionalCreatives: [],

  NoTargetLinks: false,
})

const errors = () => ({
  CreativeGroupName: null,
  CreativeGroupStartDate: null,
  CreativeGroupEndDate: null,
  IsSelfPromotion: null,
  Type: null,
  Form: null,
  AdvertiserUrls: null,
  Description: null,
  IsNative: null,
  IsSocial: null,
  TargetAudienceParams: null,
  global: []
})

export default {
  name: 'AddCreative',
  components: {
    ButtonElement,
    Selector,
    SingleDatePicker,
    Tooltip
  },
  mixins: [fixDateFormat, formValidate, numberWithSpaces],
  data() {
    return {
      AdFormKeys,
      AdType,
      AdForm,
      ContractVariant,
      ContractType,
      VatType,
      form: form(),
      errors: errors(),
      loading: false,
      formDataTextUniqKeys: [ getRandomInteger() ],
      additionalCreativesKeys: [],
      searchValueByGEO: "",
    }
  },
  computed: {
    ...mapState('Creatives', ['creativeGroups', 'fiasObjects']),
    ...mapGetters('Creatives', ['getCreativeGroupById']),
    getClientId() {
      return this.$route.query.clientId
    },
    getFinalContractId() {
      return this.$route.query.finalContractId
    },
    getInitialContractId() {
      return this.$route.query.initialContractId
    },
    getFiasObjectOptions() {
      return this.fiasObjects.map((fiasObject) => {
        const fiasObjectAlreadyAdded = this.form.TargetAudienceParams.Geo.find((objectGuid) => objectGuid === fiasObject.ObjectGuid)

        return {
          id: fiasObject.ObjectGuid, 
          name: fiasObject.Name,
          ...( fiasObjectAlreadyAdded && { disabledOption: true } )
        }
      })

    },
    isBtnDisabled() {
      return !(this.form.CreativeGroupName 
        && this.form.Form 
        && this.form.Description
        && (this.textDataHasValue || this.mediaDataHasValue)
        && (this.formHasAdditionalCreatives ? this.form.AdditionalCreatives.every(MediaDataItem => MediaDataItem.MediaData.length) : true)
      )
    },
    creativeGroupOptions() {
      return [
        {
          id: 'NoCreativeGroup',
          name: '<Новая группа креативов>'
        },
        ...this.creativeGroups.map((item) => ({ id: item.CreativeGroupId, name: item.CreativeGroupName })),
      ]
    },
    mediaDataHasValue() {
      return !!this.form.MediaData.length
    },
    formHasAdditionalCreatives() {
      return !!this.form.AdditionalCreatives.length
    },
    textDataHasValue() {
      return !!this.form.TextData.filter(({ TextData }) => TextData.length).length
    },
    targetAudienceParamsHaveGeoValue() {
      return this.form.TargetAudienceParams.Geo.filter(fiasObject => fiasObject.length)
    }
  },
  methods: {
    ...mapMutations('Creatives', [
      'clearPagination', 
      'clearFilters', 
      'increaseTableComponentKey',
      'setPagination'
    ]),
    getRandomInteger,
    changeGeoOption(val, index) {
      this.form.TargetAudienceParams.Geo.splice(index, 1, val)
      this.searchValueByGEO = ""
    },
    addAdditionalCreative() {
      this.form.AdditionalCreatives.push({ 
        MediaDataDescription: null,
        AdvertiserUrls: [""],
        MediaData: [],
        NoTargetLinks: false,
      })

      this.additionalCreativesKeys.push(this.getRandomInteger())
    },
    async prepareRequestToSend(payload) {
      if (this.formHasAdditionalCreatives) {
        const promises = []
        const formatAdditionalCreatives = this.form.AdditionalCreatives.map((additionalCreative) => ({
          ...payload,
          ...additionalCreative,
          MediaData: additionalCreative.MediaData.map((mediaDataItem) => ({ ...mediaDataItem, Description: additionalCreative.MediaDataDescription }))
        }))
        
        /* создаем первый креатив, чтобы получить CreativeGroupId для AdditionalCreatives */
        await this.$store.dispatch('Creatives/createCreative', payload).then((createdCreative) => {
          formatAdditionalCreatives.map(async (creative) => {        
            promises.push(this.$store.dispatch('Creatives/createCreative', {
              ...creative,
              CreativeGroupId: createdCreative.CreativeGroupId,
              FinalContractId: null, /* если передаем CreativeGroupId, то MediaScout не требует FinalContractId */ 
              InitialContractId: null, /* если передаем CreativeGroupId, то MediaScout не требует InitialContractId */ 
            }))
          })
        })

        return Promise.all(promises)
      } else {
        return this.$store.dispatch('Creatives/createCreative', payload)
      }
    },
    async createCreative() {
      this.loading = true 

      this.cleanErrors()
      const payload = {
        CreativeGroupName: this.form.CreativeGroupName,
        IsSelfPromotion: this.form.IsSelfPromotion,
        Type: this.form.Type,
        Form: this.form.Form,
        AdvertiserUrls: this.form.AdvertiserUrls.filter((avertiserUrl) => avertiserUrl.length),
        Description: this.form.Description,
        IsNative: this.form.IsNative,
        IsSocial: this.form.IsSocial,
        OkvedCodes: this.form.OkvedCodes.filter((OkvedCode) => OkvedCode.length),
        TargetAudienceParams: this.targetAudienceParamsHaveGeoValue.length ? this.form.TargetAudienceParams : null,
        ...(this.mediaDataHasValue && { MediaData: this.form.MediaData.map((mediaDataItem) => ({ ...mediaDataItem, Description: mediaDataItem.Description })) }),
        ...(this.textDataHasValue && { TextData: this.form.TextData }),
        ...(this.getFinalContractId && !this.form.CreativeGroupId && { FinalContractId: this.getFinalContractId }),
        ...(this.getInitialContractId && !this.form.CreativeGroupId && { InitialContractId: this.getInitialContractId }),
        ...(this.form.CreativeGroupStartDate && {CreativeGroupStartDate: this.fixDateFormatNumbers(this.form.CreativeGroupStartDate)}),
        ...(this.form.CreativeGroupEndDate && {CreativeGroupEndDate: this.fixDateFormatNumbers(this.form.CreativeGroupEndDate)}),
        ...(this.form.CreativeGroupId && {CreativeGroupId: this.form.CreativeGroupId}),
      }
    
      await this.prepareRequestToSend(payload)
      .then(() => {
        this.cleanData()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
        this.$notify({
          message: this.$t('new_app.success'),
          type: 'success'
        });
        
        this.clearPagination()
        this.clearFilters()
        this.$store.dispatch('Creatives/getCreatives', {
          ...(this.getFinalContractId && { FinalContractId: this.getFinalContractId }),
          ...(this.getInitialContractId && { InitialContractId: this.getInitialContractId }),
          ...(this.getClientId && { ClientId: this.getClientId }),
        })
        .then((creatives) => {
          this.increaseTableComponentKey()

          this.setPagination({
            pageSize: 20,
            total: creatives.length,
            currentPage: 1,
          })
        })
      })
      .catch((err) => {
        // const objErrors = JSON.parse(err.data.errors.replace('Mediascout error request:', ''))
        const objErrors = Object.prototype.hasOwnProperty.call(err, 'Mediascout error request') ? JSON.parse(JSON.stringify(err).replace('Mediascout error request:', '')) : JSON.parse(JSON.stringify(err));
        objErrors.ErrorItems.map((errorItem) => {
          if (errorItem.PropertyName) {
            this.errors[errorItem.PropertyName] = [
              ...(this.errors[errorItem.PropertyName] || []),
              errorItem.ErrorMessage,
            ]
          } else {
          this.errors.global = [...this.errors.global, errorItem.ErrorMessage]
        }

          this.$notify({
            message: errorItem.ErrorMessage,
            type: 'error'
          });
        })
      }).finally(() => {
        this.loading = false
      })
    },
    cleanData() {
      this.$refs.adForm.selectedValue = null
      this.form = {...form()}
    },
    changeCreativeGroupStartDate(dateRange) {
      this.form.CreativeGroupStartDate = dateRange.startDate
    },
    changeCreativeGroupEndDate(dateRange) {
      this.form.CreativeGroupEndDate = dateRange.startDate
    },
    changeOkvedCodes(event) {
      this.form.OkvedCodes = event.target.value.trim().split(',')
    },
    changeAdForm(val) {
      this.form.Form = val
      const { MediaData, TextData, AdvertiserUrls, AdditionalCreatives } = form()
      this.form.MediaData = MediaData
      this.form.TextData = TextData
      this.form.AdvertiserUrls = AdvertiserUrls
      this.form.AdditionalCreatives = AdditionalCreatives
    },
    changeNoTargetLinks() {
      this.form.AdvertiserUrls = [""]
    },
    cleanErrors() {
      this.errors = errors()
    },
    checkFile(e, form) {
      Array.from(e.target.files).map(async (file) => {
        const fileContentBase64 = await toBase64(file)

        form.push({
          FileName: file.name,
          FileContentBase64: fileContentBase64.split(',')[1], /* strip type */
          Description: this.form.MediaDataDescription,
          IsArchive: file.type === 'application/zip',
        })
      })
    },
    closeDialog() {
      this.cleanData()
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
    },
    handleChangeCreativeGroup(creativeGroupId) {
      this.cleanData()
      this.cleanErrors()

      const creative = this.getCreativeGroupById(creativeGroupId)

      if (creative) {
        this.$nextTick(() => {
          this.form = {
            ...this.form,
            CreativeGroupId: creative.CreativeGroupId, 
            IsNative: creative.IsNative,
            IsSocial: creative.IsSocial,
            Description: creative.Description,
            Type: creative.Type,
            Form: creative.Form,
            OkvedCodes: creative.OkvedCodes,
            CreativeGroupStartDate: creative.CreativeGroupStartDate,
            CreativeGroupEndDate: creative.CreativeGroupEndDate,
            CreativeGroupName: creative.CreativeGroupName,
            TargetAudienceParams: creative.TargetAudienceParams || { Geo: [''] },
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: 0.6rem;
}

.form-input__icons-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.form-input__icon-item {
  cursor: pointer;
  z-index: 1;
  width: 1.6rem;
  height: 1.6rem;
}

.add-creative {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__tooltip-attached-to-bottom {
    top: initial; bottom: 10px;
  }

  &__arrow-icon {
    cursor: pointer;
    transition: 0.3s ease-out;

    &--open {
      transition: 0.3s ease-out;
      transform: rotate(180deg);
    }
}

  &__upload {
    margin: 1rem 0;
    input {
      display: none;
    }

    &-files {
      width: 100%;
      margin-bottom: 10px;

      &-item {
        padding: 1.4rem 0;
        border-bottom: 1px solid $gray7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border: none;
        }
      }

      &-delete {
        background: none;
        border: none;
      }
    }

    .button {
      font-weight: 400;
      color: var(--primary);
      background: #fff;
      font-size: 1.6rem;
      line-height: 1.9rem;
      border-radius: 4px;
      border: 1.5px solid var(--primary);
      min-width: 12.5rem;
      transition: 0.3s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: var(--primary);
      }
    }
  }

  &__date-picker-group {
    display: flex;
    gap: 1.6rem;
  }

  &__reservation {
    padding-bottom: 3.2rem;
  }

  &__media-data {
    margin-top: 2rem;
  }

  &__creative, &__additional-creative {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: .1rem solid #D1D4D8;
  }

  &__delete-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__date-picker-wrapper {
    width: 100%;
  }

  &__date-picker {
    width: 100%;
    background: $basicWhite;
  }

  &__input-icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__group-btns {
    display: flex;
    gap: .8rem;
    margin-top: 4.8rem;
    flex-direction: column;
  }

  &__btns-wrapper {
    display: flex;
    gap: .8rem;
  }

  &__delete-creative-btn {
    margin-bottom: 1rem;
  }

  &__btn {  
    margin-top: 1rem;
    padding: 0 2rem;
  }

  &__save-btn {
    width: 17.4rem;
  }

  &__cancel-btn {
    width: 13.7rem;
  }
  
  
  textarea {
    font-family: 'Arial';
  }

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 2.3rem;
    color: #000;
  }

  &__sub-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
    margin-bottom: 2rem;
  }

  &__content {
    padding: 7rem;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }
  &__input {
    .selector__content {
      width: 100%;
    }
  }

  .button {
    height: 5rem;
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

@include below(993px) {
  .add-creative__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 2.3rem;
  }

  .add-creative__step{
    margin-bottom: 30px;
  }

  .add-creative__final,
  .add-creative__content{
    padding: 15px;
    padding-right: 35px;
  }
}

@include below(769px) {
  .add-creative__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
